<template>
  <div class='container'>
    <div class="header">
      <div class="options">
        <div :class="['option',activeIndex===1?'active':'']" @click="tabChange(1)">
          静态测评预警
        </div>
        <div :class="['option',activeIndex===2?'active':'']" @click="tabChange(2)">
          动态观察预警
        </div>
        <div :class="['option',activeIndex===3?'active':'']" @click="tabChange(3)">
          预警解除名单
        </div>
      </div>
      <div class="search">
        <van-icon name="search" size="4.8vw"/>
        <input type="text" v-model="listQuery.trueName" placeholder="请输入标题进行查找">
        <button @click="search">搜索</button>
      </div>
    </div>
    <div class="static-list" v-if="activeIndex===1">
      <div class="total">
        <div style="margin-left: 2.6667vw;color: #666;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</div>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
          <div :class="['status',item.sysWarningLevel==='无'?'':'warn']">
            <p>{{item.sysWarningLevel==='无'?'暂无':item.sysWarningLevel}}预警</p>
          </div>
        </div>
        <p class="omit" style="color: #666;">预警量表：<span style="color: #999;">{{item.projectNames}}</span></p>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time1">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
          <div class="btns" style="display: flex;align-items: center;flex:1;">
            <button  @click="$router.push({path:'/project/reportDetail',query:{id:item.id}})">报告</button>
            <button @click="isDialog=true">评估</button>
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="static-list" v-else-if="activeIndex===2">
      <div class="total">
        <p style="margin-left: 2.6667vw;color: #666;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生存在预警</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
           <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time1">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
          <div class="btns" style="display: flex;align-items: center;">
            <button @click="$router.push({path:'/warn/surveyDetail',query:{id:item.fromId}})">详情</button>
            <button @click="isDialog=true">评估</button>
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <div class="static-list" v-else-if="activeIndex===3">
      <div class="total">
        <p style="margin-left: 2.6667vw;color: #666;">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名学生已解除预警</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;justify-content: space-between;align-items: center;">
           <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.trueName}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
        </div>
        <p class="omit" style="color: #666;margin-top: 2.6667vw;">预警方式：<span style="color: #999;">{{item.fromType===1?'静态测评预警':'动态测评预警'}}</span></p>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time1">
            <img src="@/assets/project/time.png" alt="">
            <p>{{item.created_at}}</p>
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂无学生存在预警" v-if="list.length<=0"></van-empty>
    <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'80%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 4.2667vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option">
            <!-- <p>姓名</p> -->
            <input type="text" v-model="listQuery.trueName" placeholder="姓名">
          </div>
          <div class="option">
            <!-- <p>年级</p> -->
            <div class="selectInput">
              <input type="text" v-model="gradeName" @click="showPopup('grade')" placeholder="年级" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select">
            <!-- <p>班级</p> -->
            <div class="selectInput">
              <input type="text" v-model="listQuery.studyClass" @click="showPopup('class')" placeholder="班级" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select" v-if="activeIndex===1">
            <!-- <p>预警等级</p> -->
            <div class="selectInput">
              <input type="text" v-model="levelName" @click="showPopup('level')" placeholder="预警等级" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select" v-if="activeIndex===3">
            <!-- <p>预警方式</p> -->
            <div class="selectInput">
              <input type="text" v-model="fromTypeName" @click="showPopup('fromType')" placeholder="预警方式" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
          <div class="option select" v-if="activeIndex===1">
            <!-- <p>场次名</p> -->
            <div class="selectInput">
              <input type="text" v-model="examName" @click="showPopup('exams')" placeholder="场次名" readonly>
              <img class="arrow" src="@/assets/project/bottom-arrow.png" alt="" style="width: 6.4vw;height: 6.4vw;">
            </div>
          </div>
        </div>
        <div class="btns">
          <button style="color: #47D5B2;" @click="reset">重置</button>
          <button @click="filterSearch">搜索</button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-picker
        title="选择年级"
        value-key="studyClass"
        show-toolbar
        :columns="classList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='class'"
      />
      <van-picker
        title="选择班级"
        value-key="gradeName"
        show-toolbar
        :columns="gradeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='grade'"
      />
      <van-picker
        title="选择预警结果"
        value-key="title"
        show-toolbar
        :columns="levelList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='level'"
      />
      <van-picker
        title="选择预警方式"
        value-key="title"
        show-toolbar
        :columns="fromTypeTypeList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='fromType'"
      />
      <van-picker
        title="选择测评场次"
        value-key="title"
        show-toolbar
        :columns="examsScreenList"
        @confirm="onConfirm"
        @cancel="isPopup=false"
        v-if="popupName==='exams'"
      />
    </van-popup>
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/dialog-bg.png"  style="width: 40vw;height: 40vw;margin: 5.3333vw 0 4vw 0;"/>
        <p style="font-size: 5.3333vw;">移动端暂未开放评估功能，</p>
        <p style="font-size: 4vw;margin-top: 4vw;">如需对学生进行心理评估请使用PC端进行评估</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
    <Tabbar :activeIndex="2"></Tabbar>
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
import {bottomLoading} from '@/utils/util.js'
import {warningList} from '@/api/warning.js'
import {exams} from '@/api/exam.js'
import {gradeAndStudyClass} from '@/api/report.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      activeIndex:1,
      isShow:false,
      isDialog:false,
      isPopup:false,
      popupName:'',
      list:'',
      listQuery: {
        pageNum: 1,
        pageNo:1,
        pageSize: 10,
        warningStatus: 1,
        fromType: 1,
        trueName: '',
        grade: '',
        studyClass: '',
        gender: '',
        sysWarningLevel: '',
        examId: ''
      },
      // 展示数据
      gradeName:null,
      className:null,
      examName:null,
      levelName:null,
      fromTypeName:null,
      gradeList:[],
      classList:['全部'],
      examsScreenList:[],
      fromTypeTypeList:[{id:-1,title:'全部'},{id:1,title:'静态测评预警'},{id:2,title:'动态测评预警'},],
      levelList:[{id:-1,title:'全部'},{id:1,title:'一级'},{id:2,title:'二级'},{id:3,title:'三级'},],
      recordCount:0,
      totalPageCount:0,
    }
  },
  async created(){
    let {type} = this.$route.query
    if(type){
      type=Number(type)
      this.activeIndex=type
      this.tabChange(type)
    }else{
      this.getList()
    }
    this.getExamsScreenList()
    this.getGradeOrClass()
  },
  mounted(){
    bottomLoading(this.$refs.bottom,this.loadNextPage)
  },
  updated(){
  },
  methods:{
    async getList(){
      const formData={}
      for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
      }
      if(this.listQuery.warningStatus===-1){formData.warningStatus=-1}
      if(this.listQuery.studyClass==='全部'){formData.studyClass=null}

      const res = await warningList(formData)
      this.list=res.data.list
      this.totalPageCount=res.data.totalPageCount
      this.recordCount=res.data.recordCount
    },
    tabChange(index){
      this.listQuery= {
        pageNo: 1,
        pageNum: 1,
        pageSize: 10,
        warningStatus: 1,
        fromType: index,
        trueName: '',
        grade: '',
        studyClass: '',
        gender: '',
        sysWarningLevel: '',
        examId: ''
      }
      if(index===3){
        this.listQuery.fromType=''
        this.listQuery.warningStatus=-1
      }
      this.getList()
      this.activeIndex=index
    },
    reset(){
      this.gradeName=null,
      this.className=null,
      this.examName=null,
      this.levelName=null,
      this.fromTypeName=null,
      this.listQuery= {
        pageNo: 1,
        pageNum: 1,
        pageSize: 10,
        warningStatus: 1,
        fromType: this.listQuery.fromType,
        trueName: '',
        grade: '',
        studyClass: '',
        gender: '',
        sysWarningLevel: '',
        examId: ''
      }
      if(this.activeIndex===3){
        this.listQuery.fromType=''
        this.listQuery.warningStatus=-1
      }
      this.getList()
      this.isShow=false
    },
    search(){
      this.listQuery.pageNum=1
      this.getList()
      this.listQuery.trueName=''
    },
    filterSearch(){
      this.listQuery.pageNum=1
      this.getList()
      this.isShow=false
    },
    showPopup(name){
      this.popupName=name
      this.isPopup=true
    },
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      res.data.grades.unshift({grade:-1,gradeName:'全部'})
      this.gradeList=res.data.grades
    },
    async getExamsScreenList(){
      const res = await exams({pageNo:1,pageSize:100000})
      res.data.list.unshift({id:-1,title:'全部'})
      this.examsScreenList=res.data.list
    },
    // 筛选确认
    onConfirm(value){
      if(this.popupName==='fromType'){
        this.listQuery.fromType=value.id
        this.fromTypeName=value.title
      }else if(this.popupName==='class'){
        this.listQuery.studyClass=value.studyClass
        // this.className=value.title
      }else if(this.popupName==='grade'){
        this.listQuery.grade=value.grade
        this.gradeName=value.gradeName
        this.listQuery.studyClass=''
        const gradeList=this.gradeList.filter(item=>item.grade===value.grade)
        this.classList=gradeList[0].studyClassList
        this.classList.unshift({studyClass:'全部'})
        // this.classList.unshift('全部')
      }else if(this.popupName==='level'){
        this.listQuery.sysWarningLevel=value.id
        this.levelName=value.title
      }else if(this.popupName==='exams'){
        this.listQuery.examId=value.id
        this.examName=value.title
      }
      this.isPopup=false
      console.log(value);
    },
    transformGrade(grade){
      let gradeName=''
      this.gradeList.forEach(item => {
        if(item.grade===grade){
          // console.log(339,grade,item.gradeName);
          gradeName=item.gradeName
        }
      })
      return gradeName
    },
    async loadNextPage() {
      if(this.listQuery.pageNum<this.totalPageCount){
        this.listQuery.pageNum++
        this.listQuery.pageNo++
        const formData={}
        for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
        }
        if(this.listQuery.warningStatus===-1){formData.warningStatus=-1}
        const res = await warningList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 13.3333vw 3.2vw;
  background-color: #fff;
  min-height: 100%;
  .header{
    .options{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .option{
        padding: 0 4vw;
        // flex-shrink: 0;
        // width: 29.3333vw;
        height: 9.6vw;
        line-height: 9.6vw;
        text-align: center;
        // font-weight: 550;
        border-radius: 2.6667vw;
        background: #EFF9F8;
        font-size: 2.9333vw;
      }
      .option.active{
        // background-color: rgba(88, 88, 88, 1);
        font-weight: 550;
        color: #01C795 ;
      }
    }
    .search{
      margin-top: 4vw;
      padding: 0 .5333vw 0 4vw;
      display: flex;
      height: 9.6vw;
      align-items: center;
      background: #F5F7F9;
      border-radius: 13.3333vw;
      input{
        padding: 0 4vw;
        flex: 1;
        height: 100%;
        background: #F5F7F9;
        &::placeholder{
          color: #666 ;
          // font-weight: 550;
        }
      }
      button{
        padding: 0 6.6667vw;
        height: 8.5333vw;
        line-height: 8vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 13.3333vw;
        background-color: #5EDBC9 ;
      }
    }
  }
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4vw 0;
    color: rgba(149, 149, 149, 1);
  }
  .static-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .time1{
        padding: 1.3333vw 2.6667vw;
        margin-right: 4vw;
        display: flex;
        align-items: center;
        color: #797979;
        font-size: 2.9333vw;
        background: #FEF9F3;
        border-radius: .8vw;
        img{
          width: 3.2vw;
          height: 3.2vw;
          margin-right: 2.1333vw;
        }
      }
      .status{
        padding: 0 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 2.9333vw;
        width: 10.6667vw;
        height: 10.6667vw;
        border-radius: 1.3333vw;
        color: #5EDBC9 ;
        background: #E0FFFB;
        
      }
      .status.warn{
        color: #FB6D3F;
        background-color: #FEF9F3;
      }
      .btns{
        button{
          padding: 1.3333vw 5.3333vw;
          font-size: 3.2vw;
          border-radius: 1.0667vw;
          margin-right: 1.6vw;
          color: #fff;
          background-color: #5EDBC9;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .time{
        display: flex;
        justify-content: flex-start;
        p{
          font-size: 2.9333vw;
          padding: 1.3333vw 4vw;
          color: rgba(125, 125, 125, 1);
          border: .2667vw solid rgba(217, 217, 217, 1);
          border-radius: 1.0667vw;
          background-color: rgba(245, 245, 245, 1);
        }
      }
      .status{
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          padding: 1.3333vw 4vw;
          font-size: 3.2vw;
          border-radius: 1.3333vw;
          border: .2667vw solid rgb(0, 0, 0);
          background-color: #fff;
          margin-right: 1.6vw;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 6.6667vw 13.8667vw 6.6667vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 5.3333vw;
          height: 12.2667vw;
          background: #F5F7F9;
          border-radius: 13.3333vw;
          // border: .2667vw solid rgba(187, 187, 187, 1);
          &::placeholder{
            color: #666;
          }
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      padding: 0 6.6667vw;
      width: 100%;
      height: 16vw;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 12.2667vw;
        line-height: 12.2667vw;
        background: #EFF9F8;
        border-radius: 13.3333vw;
        &:last-child{
          margin-left: 6.6667vw;
          background: #10CA9B;
        }
      }
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      font-size: 5.3333vw;
      &:last-child{
        margin-top: 4vw;
        font-size: 4vw;
      }
    }
    button{
      margin: 6.6667vw;
      width: 40vw;
      height: 12.2667vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #5EDBC9;
      font-size: 4vw;
    }
  }
}
</style>